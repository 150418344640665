import { TerminatorLiteRequest, TerminatorLiteResults } from "./types";

const terminatorLiteGetParentProducts = (
  request: TerminatorLiteRequest
): Promise<TerminatorLiteResults[]> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  };

  const apiURL =
    "https://mainstem-product.azurewebsites.net/api/FindParentProduct";

  return new Promise(function (resolve) {
    fetch(apiURL, requestOptions)
      .then(function (response) {
        console.log("Terminator Lite - Response", response);
        return response.json();
      })
      .then(function (json) {
        console.log("Terminator Lite - Response - JSON", json);
        if (json.matches.length > 0) {
          const matchesAsResults = json.matches.map((match: any) => {
            return {
              id: match.id,
              name: match.name,
              confidence: match.confidence,
              url: `https://admin.mainstem.io/products/details/${match.id}`,
            } as TerminatorLiteResults;
          });

          resolve(matchesAsResults);
        } else {
          resolve([]);
        }
      })
      .catch(() => {
        resolve([]);
      });
  });
};

export { terminatorLiteGetParentProducts };
