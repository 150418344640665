import { TerminatorV2Request, TerminatorV2Results } from "./types";

const TerminatorV2GetParentProducts = (
  request: TerminatorV2Request
): Promise<TerminatorV2Results[]> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  };

  const apiURL =
    "https://mainstem-parent-product-ai.azurewebsites.net/api/FindParentProduct";

  return new Promise(function (resolve) {
    fetch(apiURL, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (json) {
        if (json.matches.length > 0) {
          const matchesAsResults = json.matches.map((match: any) => {
            return {
              id: match.id,
              name: match.name,
              confidence: match.confidence,
              url: `https://admin.mainstem.io/products/details/${match.id}`,
            } as TerminatorV2Results;
          });

          resolve(matchesAsResults);
        } else {
          resolve([]);
        }
      })
      .catch(()=>{
        resolve([]);
      })
  });
};

export { TerminatorV2GetParentProducts };
