import "./App.css";
// React
import React, { useState } from "react";
// MainStem - UI
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardHeaderActions,
  CardHeaderSubtitle,
  CardHeaderTitle,
  FormattedNumber,
  Grid,
  GridItem,
  InputText,
  ListGroup,
  Loader,
  TextArea,
  ToastContainer,
  toast,
} from "@mainstem/react-mainstem";
import { faCrystalBall } from "@fortawesome/pro-light-svg-icons";
import { terminatorLiteGetParentProducts } from "./api/terminator-lite";
import { TerminatorLiteResults } from "./api/terminator-lite/types";
import { TerminatorResults } from "./api/terminator/types";
import { TerminatorGetParentProducts } from "./api/terminator";
import { randomNames } from "./random";
import { TerminatorV2Results } from "./api/terminator-v2/types";
import { TerminatorV2GetParentProducts } from "./api/terminator-v2";

function App() {
  // Loading Indicators
  const [loadingTerminatorLite, setLoadingTerminatorLite] =
    useState<boolean>(false);
  const [loadingTerminator, setLoadingTerminator] = useState<boolean>(false);
  const [loadingTerminatorV2, setLoadingTerminatorV2] =
    useState<boolean>(false);
  // View State
  const [name, setName] = useState<string>("");
  const [sku, setSku] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [subCategory, setSubCategory] = useState<string>("");
  const [classification, setClassification] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [terminatorTime, setTerminatorTime] = useState<number | undefined>(
    undefined
  );
  const [terminatorLiteTime, setTerminatorLiteTime] = useState<
    number | undefined
  >(undefined);
  const [terminatorV2Time, setTerminatorV2Time] = useState<number | undefined>(
    undefined
  );
  // API Results
  const [terminatorLiteResults, setTerminatorLiteResults] =
    useState<TerminatorLiteResults[]>();
  const [terminatorResults, setTerminatorResults] =
    useState<TerminatorResults[]>();
  const [terminatorV2Results, setTerminatorV2Results] =
    useState<TerminatorV2Results[]>();

  const findParentProduct = (randomItemName?: string) => {
    if (!randomItemName) {
      if (!name) {
        toast.error("Please enter a name");
        return;
      }
    }
    setTerminatorTime(undefined);
    setTerminatorLiteTime(undefined);
    setTerminatorV2Time(undefined);

    setTerminatorLiteResults([]);
    setTerminatorResults([]);
    setTerminatorV2Results([]);

    setLoadingTerminatorLite(true);
    setLoadingTerminator(true);
    setLoadingTerminatorV2(true);

    const dateStart = new Date();

    const apiRequest = {
      name: randomItemName || name || "",
      sku,
      category,
      subCategory,
      classification,
      imageUrl,
      description,
    };

    terminatorLiteGetParentProducts(apiRequest)
      .then((apiResponse) => {
        const dateEnd = new Date();
        var dif = Math.abs(dateEnd.getTime() - dateStart.getTime()) / 1000;
        setTerminatorLiteTime(dif);
        setTerminatorLiteResults(apiResponse);
      })
      .finally(() => {
        setLoadingTerminatorLite(false);
      });

    TerminatorV2GetParentProducts(apiRequest)
      .then((apiResponse) => {
        const dateEnd = new Date();
        var dif = Math.abs(dateEnd.getTime() - dateStart.getTime()) / 1000;
        setTerminatorV2Time(dif);
        setTerminatorV2Results(apiResponse);
      })
      .finally(() => {
        setLoadingTerminatorV2(false);
      });

    TerminatorGetParentProducts(apiRequest)
      .then((apiResponse) => {
        const dateEnd = new Date();
        var dif = Math.abs(dateEnd.getTime() - dateStart.getTime()) / 1000;
        setTerminatorTime(dif);
        setTerminatorResults(apiResponse);
      })
      .finally(() => {
        setLoadingTerminator(false);
      });
  };

  const handleRandomTest = () => {
    const randomItem =
      randomNames[Math.floor(Math.random() * randomNames.length)];
    setName(randomItem);
    findParentProduct(randomItem);
  };

  return (
    <>
      <div style={{ display: "flex", padding: 25 }}>
        <div style={{ width: 600 }}>
          <Card>
            <CardHeader
              title={
                <CardHeaderTitle>
                  MainStem Parent Product Finder
                </CardHeaderTitle>
              }
            />
            <CardBody>
              <p>
                Enter in some product data below, or click the button to test a
                random product name.
              </p>
              <Button block color="fancy-gray" onClick={handleRandomTest}>
                Test A Random Product
              </Button>
            </CardBody>
          </Card>
          <br />
          <Card>
            <CardBody topPadding>
              <InputText
                label="Product Name"
                onChange={(newValue) => {
                  setName(newValue);
                }}
                onEnter={() => {
                  findParentProduct();
                }}
                labelRequired={true}
                value={name}
              />
              <InputText
                label="Product SKU"
                onChange={(newValue) => {
                  setSku(newValue);
                }}
                value={sku}
              />
              <Grid>
                <GridItem lg={4}>
                  <InputText
                    label="Category"
                    onChange={(newValue) => {
                      setCategory(newValue);
                    }}
                    value={category}
                  />
                </GridItem>
                <GridItem lg={4}>
                  <InputText
                    label="SubCategory"
                    onChange={(newValue) => {
                      setSubCategory(newValue);
                    }}
                    value={subCategory}
                  />
                </GridItem>
                <GridItem lg={4}>
                  <InputText
                    label="Classification"
                    onChange={(newValue) => {
                      setClassification(newValue);
                    }}
                    value={classification}
                  />
                </GridItem>
              </Grid>
              <InputText
                label="Image URL"
                placeholder="https://www.example.com/image.jpg"
                onChange={(newValue) => {
                  setImageUrl(newValue);
                }}
                value={imageUrl}
              />
              <TextArea
                label="Description"
                onChange={(newValue) => {
                  setDescription(newValue);
                }}
                value={description}
              />
              <Button
                block={true}
                color="fancy"
                icon={faCrystalBall}
                onClick={() => {
                  findParentProduct();
                }}
              >
                Find Parent Product
              </Button>
            </CardBody>
          </Card>
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ paddingLeft: 25 }}>
            <Grid>
              <GridItem lg={6} md={6} sm={12} xs={12}>
                <Card>
                  <CardHeader
                    actions={
                      <CardHeaderActions>
                        {terminatorLiteTime && (
                          <>
                            <FormattedNumber value={terminatorLiteTime} />
                            &nbsp;seconds
                          </>
                        )}
                      </CardHeaderActions>
                    }
                    subtitle={
                      <CardHeaderSubtitle>
                        <a
                          href="https://en.wikipedia.org/wiki/Levenshtein_distance"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Levenshtein Distance
                        </a>
                      </CardHeaderSubtitle>
                    }
                    title={<CardHeaderTitle>MainStem Results</CardHeaderTitle>}
                  />
                  <CardBody>
                    {loadingTerminatorLite ? (
                      <Loader />
                    ) : (
                      <>
                        <ListGroup>
                          {terminatorLiteResults?.map((result, index) => {
                            return (
                              <li key={result.id}>
                                <div style={{ display: "flex" }}>
                                  <div style={{ display: "flex", flex: 1 }}>
                                    <a
                                      href={result.url}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      #{result.id} - {result.name}
                                    </a>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flex: 0,
                                      marginLeft: 25,
                                    }}
                                  >
                                    <Badge>
                                      {result.confidence}% Confidence
                                    </Badge>
                                  </div>
                                </div>
                              </li>
                            );
                          }) || <li>No Results</li>}
                        </ListGroup>
                      </>
                    )}
                  </CardBody>
                </Card>
                <br />
                <Card>
                  <CardHeader
                    actions={
                      <CardHeaderActions>
                        {terminatorTime && (
                          <>
                            <FormattedNumber value={terminatorTime} />
                            &nbsp;seconds
                          </>
                        )}
                      </CardHeaderActions>
                    }
                    subtitle={
                      <CardHeaderSubtitle>
                        <a
                          href="https://en.wikipedia.org/wiki/Levenshtein_distance"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Levenshtein Distance
                        </a>
                      </CardHeaderSubtitle>
                    }
                    title={<CardHeaderTitle>Aidetic Results</CardHeaderTitle>}
                  />
                  <CardBody>
                    {loadingTerminator ? (
                      <Loader />
                    ) : (
                      <>
                        <ListGroup>
                          {terminatorResults?.map((result, index) => {
                            return (
                              <li key={result.id}>
                                <div style={{ display: "flex" }}>
                                  <div style={{ display: "flex", flex: 1 }}>
                                    <a
                                      href={result.url}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      #{result.id} - {result.name}
                                    </a>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flex: 0,
                                      marginLeft: 25,
                                    }}
                                  >
                                    <Badge>
                                      <FormattedNumber
                                        decimalPlaces={0}
                                        value={result.confidence * 100}
                                      />
                                      % Confidence
                                    </Badge>
                                  </div>
                                </div>
                              </li>
                            );
                          }) || <li>No Results</li>}
                        </ListGroup>
                      </>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem lg={6} md={6} sm={12} xs={12}>
                <Card>
                  <CardHeader
                    actions={
                      <CardHeaderActions>
                        {terminatorV2Time && (
                          <>
                            <FormattedNumber value={terminatorV2Time} />
                            &nbsp;seconds
                          </>
                        )}
                      </CardHeaderActions>
                    }
                    subtitle={
                      <CardHeaderSubtitle>
                        <a
                          href="https://www.sbert.net"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Siamese BERT-Networks - Machine Learning Sentence
                          Transformers
                        </a>
                      </CardHeaderSubtitle>
                    }
                    title={
                      <CardHeaderTitle>Aidetic v2 Results</CardHeaderTitle>
                    }
                  />
                  <CardBody>
                    {loadingTerminatorV2 ? (
                      <Loader />
                    ) : (
                      <>
                        <ListGroup>
                          {terminatorV2Results?.map((result, index) => {
                            return (
                              <li key={result.id}>
                                <div style={{ display: "flex" }}>
                                  <div style={{ display: "flex", flex: 1 }}>
                                    <a
                                      href={result.url}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      #{result.id} - {result.name}
                                    </a>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flex: 0,
                                      marginLeft: 25,
                                    }}
                                  >
                                    <Badge>
                                      <FormattedNumber
                                        decimalPlaces={0}
                                        value={result.confidence * 100}
                                      />
                                      % Confidence
                                    </Badge>
                                  </div>
                                </div>
                              </li>
                            );
                          }) || <li>No Results</li>}
                        </ListGroup>
                      </>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </Grid>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
